import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';


function Activities() {
  return (
    <>
      <Container className="wg-100">
        <Row id="1" className="justify-content-center text-center" style={{ color: "#1155bf", paddingBottom: "10vh" }} >
          <h1>Mentions légales</h1>
        </Row>

        <Row className="text-dark" style={{ paddingBottom: '5vh' }} >

          <Col xs={ 12 } >
            <h2>Conditions d’utilisation</h2>
            <p >
              Nous nous efforçons de vous offrir sur notre site l'information la plus fiable et actualisée qui soit. Cependant, les lois et les règlementations sont sujettes à de fréquentes modifications ; des erreurs ou omissions peuvent donc survenir.<br/>
              Les informations, données et analyses contenues sur le site ne peuvent en aucun cas être assimilées à des prestations de services ou à des conseils rendus par leurs auteurs. Aussi, elles ne peuvent être utilisées comme un substitut à une consultation rendue par une personne professionnellement compétente.<br/>
              De ce fait, les utilisateurs sont vivement encouragés à prendre contact avec les professionnels de notre cabinet avant de prendre une quelconque décision.<br/>
              L’accès et l’utilisation de ce site sont soumis aux conditions suivantes ainsi qu’aux dispositions légales en vigueur. En accédant au site, vous acceptez, sans limitation ni réserves, ces conditions.<br/>
            </p>
          </Col>

          <Col xs={ 12 } >
            <h2>Clause de non-responsabilité</h2>
            <p >
               La responsabilité du cabinet Lefevre Expertise Comptable ne peut, en aucune manière, être engagée quant au contenu des informations figurant sur ce site ou aux conséquences pouvant résulter de leur utilisation ou interprétation.
            </p>
          </Col>

          <Col xs={ 12 } >
            <h2>Propriété intellectuelle</h2>
            <p >
               La structure générale de notre Site et l’ensemble des contenus diffusés sur ce Site (images, articles, logos, textes…), sont protégés par la législation en vigueur en France en matière de propriété intellectuelle et par la législation internationale en vigueur. Tous les droits de reproduction sont réservés, y compris pour les logos, les textes, les documents téléchargeables, les représentations iconographiques. A ce titre, à défaut d’autorisation expresse du cabinet Lefevre Expertise Comptable, il est strictement interdit d’exploiter les contenus de notre Site et notamment de les reproduire, représenter, modifier ou adapter en tout ou partie.
            </p>
          </Col>

          <Col xs={ 12 } >
            <h2>Protection des données personnelles</h2>
            <p >
               Aucune information personnelle n’est cédée à des tiers. Conformément à la loi n° 78_17 du 6 janvier 1978, relative à l’informatique, aux fichiers et aux libertés (articles 38, 39, 40), vous disposez d’un droit d’accès, de rectification et de suppression des données vous concernant, en ligne sur ce site. Pour exercer ce droit, vous pouvez vous adresser au cabinet Lefevre Expertise Comptable.
            </p>
          </Col>

          <Col xs={ 12 } >
            <h2>Liens hypertextes</h2>
            <p >
               Afin de faciliter l’accès à d’autres sites susceptibles d’apporter un complément d’information, le cabinet Lefevre Expertise Comptable a inséré dans son site un certain nombre de liens hypertexte. Néanmoins, la responsabilité du cabinet ne saurait être engagée au titre d’un site tiers auquel vous auriez eu accès via notre site et présentant des contenus litigieux ou inexacts.
            </p>
          </Col>

          <Col xs={ 12 } >
            <h2>Hebergement et Webmaster</h2>
            <p >
              DRFDEV<br/>
              67 Rue de la colonie<br/>
              75013, Paris<br/>
              http://www.drf.ovh<br/>
            </p>
          </Col>

        </Row>

      </Container>
  </>
  );
}

export default Activities;
