import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';


function Activities() {
  return (
    <>
      <Container className="wg-100">
        <Row id="1" className="justify-content-center text-center" style={{ color: "#1155bf", paddingBottom: "10vh" }} >
          <h1>Documentation</h1>
        </Row>

        <Row className="text-dark" style={{ paddingBottom: '5vh' }} >
          <Col xs={ 12 } >
            <h2>Création d'entreprise</h2>
            <p >
              <a href="https://bpifrance-creation.fr/node/1163624?utm_campaign=Lettre%20d%27information%20Bpifrance%20cr%C3%83%C2%A9ation%20%2026%2F03%2F2019%20%236%20&utm_medium=email&utm_source=bpifrance-marketing-digital">
                Evaluer son idée de création d’entreprise<br/>
              </a>

              <p>Choix du statut juridique:</p> <a href="https://bpifrance-creation.fr/node/1164362?utm_campaign=Lettre%20d%27information%20Bpifrance%20cr%C3%83%C2%A9ation%20%2026%2F03%2F2019%20%236%20&utm_medium=email&utm_source=bpifrance-marketing-digital">BPI France</a> ou <a href="https://www.economie.gouv.fr/entreprises/entreprise-choisir-statut-juridique#xtor=ES-29-[Bercy_Entreprises_32_ExpComp]-20160915-[Actualite5-Statut-Juridique]">impots.gouv</a><br/>

              <a href="http://www.apce.com/">
                APCE (Agence Pour la Création d'Entreprises)<br/>
              </a>

              <a href="http://www.lautoentrepreneur.fr/" >
                L'Auto-Entrepreneur (Portail des auto-entrepreneurs)<br/>
              </a>

              <a href="http://www.experts-comptables.fr/">
                OEC (L'ordre des Experts-Comptables)<br/>
              </a>

              <a href="https://bpifrance-creation.fr/encyclopedie/porteur-projet-preparation-droits-obligations/formation/se-former-ligne-selection?utm_campaign=Lettre%20d%27information%20Bpifrance%20cr%C3%83%C2%A9ation%20%2025/06/2019%20#18&utm_medium=email&utm_source=bpifrance-marketing-digital" >
                Formation en ligne sur l'entrepreneuriat<br/>
              </a>

              <a href="https://bpifrance-creation.fr/node/1152028?utm_campaign=Lettre%20d%27information%20Bpifrance%20cr%C3%83%C2%A9ation%20%2011%2F06%2F2019%20%2316&utm_medium=email&utm_source=bpifrance-marketing-digital">
                Guide pratique du créateur<br/>
              </a>

              <a href="http://www.aides-entreprises.fr/" >
                Aides aux Entreprises<br/>
              </a>

              <a href="https://www.economie.gouv.fr/entreprises/crowdfunding-financement-participatif?xtor=ES-29-[BIE_170_20190620]-20190620-[https://www.economie.gouv.fr/entreprises/crowdfunding-financement-participatif]-271232" >
                Informations sur le crowdfunding<br/>
              </a>

              <a href="https://www.cma-essonne.fr/" >
                Chambre de métiers et de l'artisanat de l'Essonne<br/>
              </a>

              <a href="https://www.essonne.cci.fr/" >
                Chambre de commerce et d’industrie de l’Essonne<br/>
              </a>
            </p>
          </Col>
        </Row>
        <Row className="text-dark" style={{ paddingBottom: '5vh' }} >
          <Col xs={ 12 } >
            <h2>Documentation</h2>
            <p >
              <a href="http://www.economie.gouv.fr/vous-orienter/particulier/bercy-infos">
                Abonnement lettre d'information Bercy<br/>
              </a>

              <a href="http://www.impots.gouv.fr/">
                Impots.Gouv.Fr
                (Recherche de formulaires fiscaux et simulateur de calcul.)<br/>
              </a>

              <a href="http://bofip.impots.gouv.fr/bofip/1-PGP.html">
                Bulletin Officiel des Finances Publiques-Impôts
                (Documentation Fiscale.)<br/>
              </a>

              <a href="download/Fiche_CLIENT_Creation_Entreprise.pdf">
                Création d'entreprise<br/>
              </a>

              <a href="download/Cr%C3%A9ation%20entreprises%20plaquette%20%C3%A9tapes.pdf" >
                Les étapes de la création d'entreprise<br/>
              </a>

              <a href="download/Guide%20organisation%20administrative%20et%20comptable.pdf" >
                Le guide de l'organisation administrative et comptable<br/>
              </a>

              <a href="download/Downloads/Planning%20client.pdf">
                Les échéances<br/>
              </a>

              <a href="download/Factures%20mentions%20obligatoires%20avec%20exemple.pdf">
                Les mentions sur les factures</a> - <a href="https://www.economie.gouv.fr/entreprises/factures-mentions-obligatoires?xtor=ES-29-[BIE_183_20190919_objetbis]-20190919-[https://www.economie.gouv.fr/entreprises/factures-mentions-obligatoires]-271232"> autre lien<br/>
              </a>

              <a href="download/TVA%2001%2001%202014.pdf">
                Les taux de TVA au 01/01/2014<br/>
              </a>

              <a href="download/TVA%20Attestation.pdf">
                L'attestation de TVA<br/>
              </a>

              <a href="download/TVA%20Autoliquidation%20%C3%A0%20compter%20du%2001%2001%202014.pdf">
                L'autoliquidation de la TVA dans le secteur du batiment<br/>
              </a>

              <a href="https://www.economie.gouv.fr/entreprises/delai-conservation-documents?xtor=ES-29-[BIE_291_20211230_BestOf]-20211230-[https://www.economie.gouv.fr/entreprises/delai-conservation-documents]">
                Les durées de conservation des documents pour les entreprises<br/>
              </a>

              <a href="https://www.economie.gouv.fr/entreprises/obligations-affichage-informations-salaries?xtor=ES-29-[BIE_258_20210429]-20210429-[https://www.economie.gouv.fr/entreprises/obligations-affichage-informations-salaries]">
                Les obligations d’affichage<br/>
              </a>

              <a href="https://www.compteprofessionnelprevention.fr/home.html">
                Le compte pénibilité<br/>
              </a>

              <a href="download/Guide%20RGPD%20PME.pdf">
                Le guide pratique de sensibilisation au RGPD<br/>
              </a>

              <a href="https://www.economie.gouv.fr/entreprises/cybersecurite-mooc-proteger-donnees?xtor=ES-29-[BIE_134_20181011_NonExpertsComptables]-20181011-[https://www.economie.gouv.fr/entreprises/cybersecurite-mooc-proteger-donnees]-271232">
                Cybersécurité : se former pour apprendre à protéger vos données<br/>
              </a>

              <a href="https://www.cybermalveillance.gouv.fr/tous-nos-contenus/a-propos/kit-de-communication">
                Assistance et prévention du risque numérique<br/>
              </a>

              <a href="download/IR%20Pr%C3%A9l%C3%A8vement%20%C3%A0%20la%20source%20pas-livret2.pdf">
                Tout savoir sur le prélèvement à la source - A destination des entreprises<br/>
              </a>

              <a href="download/IR%20Pr%C3%A9l%C3%A8vement%20%C3%A0%20la%20source%20pas-livret3.pdf">
                Tout savoir sur le prélèvement à la source - A destination des indépendants<br/>
              </a>

              <a href="http://www.economie.gouv.fr/prelevement-a-la-source/faq">
                Tout savoir sur le prélèvement à la source - Foire aux Questions<br/>
              </a>
            </p>
          </Col>
        </Row>

      </Container>
    </>
  );
}

export default Activities;
