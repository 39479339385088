import React, { useState, useEffect } from 'react'
import logo from '../statics/logo.png';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { slide as Menu } from 'react-burger-menu';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Bar from '../components/Bar';

function Header() {
  const { currentUser, logout } = useAuth();
  const [currentPath, setCurrentPath] = useState('');
  const [colorChange, setColorchange] = useState("#ffffff00");
  const { pathname } = useLocation();

  useEffect(() => {
    setCurrentPath(pathname.slice(1));
  }, [pathname]);

  const changeNavbarColor = () =>{
    if(window.scrollY <= 500){
      setColorchange("#ffffff" + (window.scrollY / 2).toString(16).padStart(2, '0'));
    }
    else{
      setColorchange("#fff");
    }
  };
  const closeBurgerMenu = (e: any) => {
    const element: HTMLElement = document.getElementsByClassName("bm-overlay")[0] as HTMLElement;
    element.click();
  }
  const handleLogout = async (e: any) => {
    e.preventDefault();
    try {
      await logout();
    } catch {
    }
  }
  window.addEventListener('scroll', changeNavbarColor);

  return (
    <>
      <Menu right >
        <Link onClick={ closeBurgerMenu } to="/" className="menu-item" >Accueil</Link>
        <Link onClick={ closeBurgerMenu } to="/activites" className="menu-item" >Activités</Link>
        <Link onClick={ closeBurgerMenu } to="/outils" className="menu-item" >Outils</Link>
        <Link onClick={ closeBurgerMenu } to="/documentation" className="menu-item" >Documentation</Link>
    { (!currentUser || !currentUser.email) && <Link onClick={ closeBurgerMenu } to="/login" className="menu-item">Connexion</Link> }
    { currentUser && currentUser.email &&
    <>
      <Bar />
      <p>Mon compte</p>
      <Link onClick={ closeBurgerMenu } className="menu-item" to="/actualites">Actualités</Link><br/>
      <Link onClick={ closeBurgerMenu } className="menu-item" to="/fichiers">Mes fichiers</Link><br/>
      <a href="/" className="menu-item" onClick={ (e: any) => { closeBurgerMenu({}); handleLogout(e); } } >Déconnexion</a>
    </>
  }
      </Menu>
      <div className="top-background-cover" >
        <div className="top-background-gradient" >
        </div>
      </div>
      <Navbar style={{ zIndex: 1, backgroundColor: colorChange }} variant="light" fixed="top" >
        <Navbar.Brand as={ Link } to="/" style={{ color: "#1155bf", fontSize: "2rem" }} className="mr-auto">
              <img src={ logo } alt="logo EC" width="150px" />
</Navbar.Brand>
        <Nav className="justify-content-end navbar-links">
          <Nav.Link as={ Link } active={ currentPath === "" } to="/">Accueil</Nav.Link>
          <Nav.Link as={ Link } active={ currentPath === "activites" } to="/activites">Activités</Nav.Link>
          <Nav.Link as={ Link } active={ currentPath === "outils" } to="/outils">Outils</Nav.Link>
          <Nav.Link as={ Link } active={ currentPath === "documentation" } to="/documentation">Documentation</Nav.Link>
    { (!currentUser || !currentUser.email) && <Nav.Link  as={ Link } active={ currentPath === "login" } to="/login">Connexion</Nav.Link> }
    { currentUser && currentUser.email && 
    <NavDropdown active={ currentPath.startsWith("actualites") || currentPath.startsWith('fichiers') } title="Mon compte" id="basic-nav-dropdown">
      <NavDropdown.Item disabled  as={ Link } to="/compte">Mon compte</NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item as={ Link } to="/actualites">Actualités</NavDropdown.Item>
      <NavDropdown.Item as={ Link } to="/fichiers">Mes fichiers</NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item onClick={ handleLogout } >Déconnexion</NavDropdown.Item>
    </NavDropdown>
  }
        </Nav>
      </Navbar>
    </>
  );
}

export default Header;
