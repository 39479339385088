import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import { config } from "./config";

const app = firebase.initializeApp(config);

export const db = app.database();
export const storage = app.storage();
export const auth = app.auth();
export default app;
