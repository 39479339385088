import React, { useEffect } from 'react';
import Header from './components/Header';
import Home from './pages/Home';
import Tools from './pages/Tools';
import Login from './pages/Login';
import Legal from './pages/Legal';
import Doc from './pages/Doc';
import Actualites from './pages/Actualites';
import Fichiers from './pages/Fichiers';
import Partenaires from './pages/Partenaires';
import ActualitesSingle from './pages/ActualitesSingle';
import ActualitesNew from './pages/ActualitesNew';
import Footer from './components/Footer';
import Account from './pages/Account';
import Activites from './pages/Activites';
import AuthProvider from './contexts/AuthContext';
import Health from './pages/Health';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';
import ScrollToTop from './components/ScrollToTop';


function App() {
  useEffect(() => {
    document.title = "LEFEVRE EXPERTISE COMPTABLE"
  }, [])
  return (
    <Router>
      <ScrollToTop/>
      <script src="https://cdn.tiny.cloud/1/no-api-key/tinymce/5/tinymce.min.js"></script>
      <div className="App">
        <AuthProvider>
          <Header />
          <Switch >
            <Route path="/health" component={ Health } />
            <Route path="/outils" component={ Tools } />
            <Route path="/documentation" component={ Doc } />
            <Route path="/activites" component={ Activites } />
            <Route path="/login" component={ Login } />
            <Route path="/legal" component={ Legal } />
            <Route path="/partenaires" component={ Partenaires } />
            <Route path="/actualites/nouvelle" component={ ActualitesNew } />
            <Route path="/actualites/edit/:id" component={ ActualitesNew } />
            <Route path="/actualites/:id" component={ ActualitesSingle } />
            <Route path="/actualites" component={ Actualites } />
            <Route path="/fichiers" component={ Fichiers } />
            <Route path="/compte" component={ Account } />
            <Route path="/" component={ Home } />
          </Switch>
          <Footer />
        </AuthProvider>
      </div>
    </Router>
  );
}

export default App;
