import React, { useState, useRef, useEffect } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';

function Activites() {
  const { currentUser } = useAuth();
  const newsRef = useRef<any>([]);
  const [news, setNews] = useState([]);

  useEffect(() => {
    db.ref('news/').orderByValue().on('value', (v: any) => {
      var e: any;
      const tmp = v.val();
      for (e in tmp) {
        tmp[e].id = e;
        newsRef.current.push(tmp[e]);
      }
      setNews(newsRef.current.reverse());
    });
  }, []);

  return (
    <>
      { (!currentUser || !currentUser.email) && <Redirect to="/" /> }
      <Container className="wg-100 actualites-parent">
        <Row className="justify-content-center text-center" style={{ color: "#1155bf", paddingBottom: "10vh" }} >
          <h1>Actualités</h1>
        </Row>
          { currentUser && currentUser.email === "nl@lefevre-ec.fr" &&
          <Row style={{ paddingBottom: '5vh' }} >
            <Link to="/actualites/nouvelle" >Créer une nouvelle actualité</Link>
          </Row>
          }

    { news.map((value: any, index: any) => {
      return <Link to={ `/actualites/${value.id}` } >
        <Row className="actualites justify-content-center" style={{ paddingBottom: '5vh' }} >
          <Col xs={ 12 } >
            <Row>
              <Col>
                <h2>{ value.title }</h2>
                <h3>{ moment(-value.date).format('DD/MM/YYYY') }</h3>
                <p>
                  { value.summary }
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Link>
    })}

  </Container>
</>
  );
}

export default Activites;
