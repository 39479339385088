import React, { useState } from 'react';
import logoEc from '../statics/logo_croec.jpg';
import qrCode from '../statics/qr.png';
import { Row, Col, Container } from 'react-bootstrap';
import Bar from '../components/Bar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faUser, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { ResponsivePie } from '@nivo/pie'

interface IHoverIcon {
  first?: boolean;
  second?: boolean;
  third?: boolean;
}

function Home() {
  const [isHoveredIcon, setIsHoveredIcon] = useState<IHoverIcon>({});

  const totalClient = 16;
  const secteurClientData = [
    {
      "id": "Industrie",
      "label": "Industrie",
      "value": (1 / totalClient) * 100,
    },
    {
      "id": "Location immobilière",
      "label": "Location immobilière",
      "value": (1 / totalClient) * 100,
    },
    {
      "id": "Agriculture",
      "label": "Agriculture",
      "value": (1 / totalClient) * 100,
    },
    {
      "id": "Bâtiment",
      "label": "Bâtiment",
      "value": (2 / totalClient) * 100,
    },
    {
      "id": "Commerce",
      "label": "Commerce",
      "value": (5 / totalClient) * 100,
    },
    {
      "id": "Prestation services",
      "label": "Prestation services",
      "value": (6 / totalClient) * 100,
    }
  ];

  return (
    <>
      <Container className="wg-100">
        <Row className="justify-content-center text-center" style={{ color: "#1155bf", paddingBottom: "10vh" }} >
          <h1 style={{ fontSize: '2.5rem' }} >LEFEVRE EXPERTISE COMPTABLE</h1>
        </Row>
        <Row className="justify-content-center" >

          <Col xs={ 12 } lg={ 4 } onMouseEnter={() => setIsHoveredIcon({ first: true })} onMouseLeave={() => setIsHoveredIcon({})} >
            <Row>
              <Col xs={ 2 } className="align-self-center text-center" >
                <FontAwesomeIcon icon={ faUser } color={ ((!isHoveredIcon.first) && "#1155bf") || "#a4c2f4" } size="3x" />
              </Col>
              <Col>
                <h2>Nathalie LEFEVRE</h2>
                <p>
                  Expert-comptable inscrite au tableau de l'ordre des
                  experts-comptables de Paris Ile de France.
                </p>
              </Col>
            </Row>
          </Col>

          <Col xs={ 12 } lg={ 4 } onMouseEnter={() => setIsHoveredIcon({ second: true })} onMouseLeave={() => setIsHoveredIcon({})} >
            <Row>
              <Col xs={ 2 } className="align-self-center text-center" >
                <FontAwesomeIcon icon={ faMapMarkerAlt } color={ (!isHoveredIcon.second && "#1155bf") || "#a4c2f4" } size="3x" />
              </Col>
              <Col>
                <h2>Localisation</h2>
                <p>
                  Nos locaux sont situés à Gometz la Ville, accessible
                  facilement depuis Les Ulis comme depuis Limours.
                </p>
              </Col>
            </Row>
          </Col>

          <Col xs={ 12 } lg={ 4 } onMouseEnter={() => setIsHoveredIcon({ third: true })} onMouseLeave={() => setIsHoveredIcon({})} >
            <Row>
              <Col xs={ 2 } className="align-self-center text-center" >
                <FontAwesomeIcon icon={ faPhone } color={ (!isHoveredIcon.third && "#1155bf") || "#a4c2f4" } size="3x" />
              </Col>
              <Col>
                <h2>Contact</h2>
                <p>
                  Tel : <a href="tel:0184180066">01 84 18 00 66</a><br/>
                  Fax : 09 72 29 43 65<br/>
                  Email : <a href="mailto:contact@lefevre-ec.fr">contact@lefevre-ec.fr</a>
                </p>
              </Col>
            </Row>
          </Col>

        </Row>
        <Bar />

        <Row className="justify-content-center" style={{ paddingBottom: "2vh" }} >
          <Col xs={ 12 } md={ 8 } >
            <h2>Horaires d'ouverture</h2>
            <p>
              Ouvert du lundi au vendredi, de 9h30 à 12h30 et de 14h
              à 17h30 ou sur rendez-vous
            </p>
            <h3>N'hésitez pas à convenir d'un rendez-vous au <a href="tel:0184180066">01 84 18 00 66</a></h3>

          </Col>

          <Col xs={ 12 } md={ 4 } >
            <h2>Adresse</h2>
            <p>
              4 Clos de la boulaye
              91400, Gometz la Ville
            </p>
          </Col>
        </Row>
        <Row style={{ paddingBottom: '5vh' }} >
          <Col xs={ 12 } md={ 8 } >
            <div style={{ paddingLeft: '7%' }} >
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2437.112026710098!2d2.1238284482063676!3d48.67179972778445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e5d509237d30d5%3A0x261bcb7ae382f3d3!2s4%20Clos%20de%20la%20Boulaye%2C%2091400%20Gometz-la-Ville!5e0!3m2!1sen!2sfr!4v1621447375348!5m2!1sen!2sfr" width="90%" height="450px" style={{ border:"0", maxHeight: '50vh' }} loading="lazy" title="location" ></iframe>
            </div>

          </Col>
          <Col xs={ 12 } md={ 4 } >
            <img src={ logoEc } style={{ width: '80%', paddingLeft: '20%', paddingTop: '5vh' }} alt="logo ec" />
            <img src={ qrCode } style={{ width: '75%', paddingLeft: '25%' }} alt="qr code" />
          </Col>
        </Row>

      </Container>
      <Container fluid  style={{ paddingLeft: '0', paddingRight: '0' }} >

        <Row className="justify-content-center " style={{ paddingTop: '5vh', backgroundColor: '#1155bf', marginLeft: '0', marginRight: '0' }} >
          <Row className="justify-content-center text-center text-light" >
            <Col xs={ 12 } >
              <h2>Nos Valeurs</h2>
            </Col>
          </Row>
        </Row>
        <Row className="justify-content-center " style={{ paddingTop: '5vh', backgroundColor: '#1155bf', paddingBottom: '5vh', marginLeft: '0', marginRight: '0' }} >
            <Row className="justify-content-center text-light" style={{ marginLeft: '0', marginRight: '0' }}>
              <Col xs={ 12 } md={ 4 } >
                <p style={{ borderLeft: '2px solid #ccc', padding: '3vh 30px' }}>
                  Un service de qualité centré sur le client.
                </p>
              </Col>
              <Col xs={ 12 } md={ 4 } >
                <p style={{ borderLeft: '2px solid #ccc', padding: '3vh 30px' }}>
                  Expert comptable polyvalente, acceptant les entreprises
                  de tous domaines et de toutes tailles.
                </p>
              </Col>
            </Row>
        </Row>

        <Row className="justify-content-center " style={{ paddingTop: '5vh', backgroundColor: '#3d5f96', marginLeft: '0', marginRight: '0' }} >
          <Row className="justify-content-center text-center text-light" >
            <Col xs={ 12 } >
              <h2>Secteurs d'activité de la clientèle</h2>
            </Col>
          </Row>
        </Row>
        <Row className="justify-content-center " style={{ paddingTop: '5vh', backgroundColor: '#3d5f96', marginLeft: '0', marginRight: '0' }} >
          <Row className="justify-content-center text-center text-light" >
            <Col xs={ 12 } style={{ height: '400px', width: '800px', color: '#555' }} >

              <ResponsivePie
                data={secteurClientData}
                margin={{ top: 20, right: 80, bottom: 50, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                enableArcLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#fff"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}

              />

            </Col>
          </Row>
        </Row>

      </Container>
  </>
  );
}

export default Home;
