import React from 'react';


function Bar() {
  return (
    <div className="justify-content-center" style={{ margin: '5vh 20%', borderBottom: '1px solid #999', width: '60%' }} >
    </div>
  );
}

export default Bar;
