import React, { useRef, useState } from 'react';
import { Row, Col, Container, Form, Button, Alert } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'


function Login() {
  const { currentUser, login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const handleForm = async (e: any) => {
    e.preventDefault();
    if (emailRef.current && passwordRef.current) {
      setLoading(true);
      setError('');
      try {
        await login(emailRef.current.value, passwordRef.current.value);
      } catch {
        setError('Invalid email or password.');
      }
      setLoading(false);
    }
  }

  return (
    <>
      { currentUser && currentUser.email && <Redirect to="/actualites" /> }
      <Container className="wg-100">
        <Row className="justify-content-center text-center" style={{ color: "#1155bf", paddingBottom: "10vh" }} >
          <h1>Connexion</h1>
        </Row>
        <Row className="justify-content-center" style={{ paddingBottom: '10vh' }} >

          <Col xs={ 12 } md={ 4 } >
            { error && <Alert variant="danger"> { error } </Alert> }
            <Form onSubmit={ handleForm } >
              <Form.Group controlId="email">
                <Form.Label>Adresse email</Form.Label>
                <Form.Control ref={ emailRef } type="email" placeholder="Adresse email" />
                <Form.Text className="text-muted">
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label>Mot de passe</Form.Label>
                <Form.Control ref={ passwordRef } type="password" placeholder="Mot de passe" />
              </Form.Group>
              <Button disabled={ loading } variant="primary" type="submit">
                Connexion
              </Button>
            </Form>
          </Col>

        </Row>

      </Container>
  </>
  );
}

export default Login;
