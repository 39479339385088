import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';


function Activities() {
  return (
    <>
      <Container className="wg-100">
        <Row id="1" className="justify-content-center text-center" style={{ color: "#1155bf", paddingBottom: "10vh" }} >
          <h1>Partenaires</h1>
        </Row>

        <Row className="text-dark" style={{ paddingBottom: '5vh' }} >

          <Col xs={ 12 } >
            <h2>DRFDEV</h2>
            <a href="https://drf.ovh">DRF Development</a>
          </Col>

          <Col xs={ 12 } >
            <h2>Sylvain Lefevre</h2>
            <a href="https://caillou.eu">caillou.eu</a>
          </Col>

        </Row>

      </Container>
  </>
  );
}

export default Activities;
