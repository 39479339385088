import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Container, Form, Button } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { Editor } from '@tinymce/tinymce-react';

function ActivitesNew(params: any) {
  const { currentUser } = useAuth();
  const titleRef = useRef<HTMLInputElement>(null);
  const resumeRef = useRef<HTMLInputElement>(null);
  const contentRef = useRef<string>('');
  const dateRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(true);
  const [done, setDone] = useState('');
  const { id } = params.match.params;

  const handleEditorChange = (e: any) => {
    if (contentRef) {
      contentRef.current = e.target.getContent();
    }
  }

  const createNew = async (e: any) => {
    e.preventDefault();
    if (titleRef.current && resumeRef.current && contentRef.current) {
      const date = dateRef.current !== null && Number(dateRef.current.value) ? dateRef.current.value : -Date.now();
      const news_id = id ? id : `news-${Date.now()}`;
      setLoading(true);

      await db.ref(`news/${news_id}`)
        .set({
          date: date,
          summary: resumeRef.current.value,
          desc: `"${contentRef.current}"`,
          title: titleRef.current.value
        });

      setDone(news_id);
    }
  };

  useEffect(() => {
    if (id) {
      db.ref(`news/${id}`).on('value', (v: any) => {
        const values = v.val();
        if (values && titleRef.current && resumeRef.current && contentRef.current !== null && dateRef.current) {
      console.log(id);
          titleRef.current.value = values.title;
          resumeRef.current.value = values.summary;
          contentRef.current = values.desc.slice(1, values.desc.length - 1);
          dateRef.current.value = values.date;
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  }, [id]);

  return (
    <>
      { (!currentUser || currentUser.email !== "nl@lefevre-ec.fr") && <Redirect to="/" /> }
      { done && <Redirect to={ `/actualites/${done}` } /> }
      <Container className="wg-100">
        <>
          <Row className="justify-content-center text-center" style={{ color: "#1155bf", paddingBottom: "10vh" }} >
            { !id &&
            <h1>Nouvelle actualité</h1>
            }
            { id &&
            <h1>Éditer une actualité</h1>
            }
          </Row>

          <Row className="actualites justify-content-center" style={{ paddingBottom: '5vh' }} >
            <Col xs={ 12 } >
              <Row>
                <Col>
                  <Form onSubmit={ createNew } >
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Titre</Form.Label>
                      <Form.Control ref={ titleRef } disabled={ loading } />
                    </Form.Group>
                    <Form.Group controlId="resume">
                      <Form.Label>Résumé</Form.Label>
                      <Form.Control ref={ resumeRef as any } as="textarea" rows={3}  style={{ minHeight: '100px' }} disabled={ loading } />
                    </Form.Group>
                    <Form.Group controlId="content">
                      <Form.Label>Contenu</Form.Label>
                      <Editor
                        initialValue={ contentRef.current }
                        apiKey="05tlp90uu47fpgapzkdtby28deo9qi8v5k3gjsfmy6oi3lmq"
                        disabled={ loading }
                        init={{
                          menubar: false,
                          height: 500,
                          plugins: [
                            'autolink',
                            'codesample',
                            'link',
                            'lists',
                            'media',
                            'table',
                            'image',
                            'quickbars',
                            'codesample',
                            'help'
                          ],
                          toolbar: false,
                          quickbars_insert_toolbar: 'quicktable image media codesample',
                          quickbars_selection_toolbar: 'bold italic underline | formatselect | blockquote quicklink',
                          contextmenu: 'undo redo | inserttable | cell row column deletetable | help',
                          powerpaste_word_import: 'clean',
                          powerpaste_html_import: 'clean',
                        }}
                        onChange={handleEditorChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="date">
                      <Form.Control ref={ dateRef } hidden />
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={ loading } >
                      Submit
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>


        </>

  </Container>
</>
  );
}

export default ActivitesNew;
