import React, { useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import firebase from "firebase/app";
type User = firebase.User;

interface IAuth {
    currentUser: User | null;
    login: (email: string, password: string) => void;
    logout: () => void;
}

const AuthContext = React.createContext<IAuth>({ currentUser: null, login: () => {}, logout: () => {} });

export function useAuth() {
  return useContext(AuthContext);
}

function AuthProvider({ children }: any) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  function login(email: string, password: string) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user === null) {
        auth.signInAnonymously()
      } else {
        setCurrentUser(user);
        setLoading(false);
      }
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    logout
  }

  return (
    <AuthContext.Provider value={ value } >
      { !loading && children }
    </AuthContext.Provider>
  );
}

export default AuthProvider;
