import React, { useState, useEffect } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import parse from "html-react-parser";
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';

interface INews {
  desc: string,
  summary: string,
  title: string,
  date: number
}

function ActivitesSingle(params: any) {
  const { currentUser } = useAuth();
  const [news, setNews] = useState<INews>();
  const [deleted, setDeleted] = useState(false);
  const { id } = params.match.params;

  const deleteNews = async () => {
    await db.ref(`news/${id}`).remove();
    setDeleted(true);
  };

  useEffect(() => {
    db.ref(`news/${id}`).on('value', (v: any) => {
      setNews(v.val());
    });
  }, [id]);

  return (
    <>
      { deleted && <Redirect to={ `/actualites/` } /> }
      { (!currentUser || !currentUser.email) && <Redirect to="/" /> }
      <Container className="wg-100">
        { news &&
        <>
          <Row className="justify-content-center text-center" style={{ color: "#1155bf", paddingBottom: "10vh" }} >
            <h1>{ news.title }</h1>
          </Row>

          <Row className="actualites justify-content-center" style={{ paddingBottom: '5vh' }} >
            <Col xs={ 12 } >
              <Row>
                <Col>
                  <h2>{ moment(-news.date).format('DD/MM/YYYY') }</h2>
                  <p>
                    { parse(news.desc.slice(1, news.desc.length - 1)) }
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        { currentUser && currentUser.email === "nl@lefevre-ec.fr" &&
      <Row style={{ paddingBottom: '5vh' }} >
        <Link to={ `/actualites/edit/${id}` } >Éditer</Link>
        <a onClick={ () => {if(window.confirm('Supprimer cette actualité?')){deleteNews()};} } href="#top" style={{ paddingLeft: '30px' }} >Supprimer</a>
      </Row>
        }
        </>
      }

  </Container>
</>
  );
}

export default ActivitesSingle;
