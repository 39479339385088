import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';


function Activites() {
  return (
    <>
      <Container className="wg-100">
        <Row id="1" className="justify-content-center text-center" style={{ color: "#1155bf", paddingBottom: "10vh" }} >
          <h1>Domaines d'activité</h1>
        </Row>

        <Row className="text-dark" style={{ paddingBottom: '5vh' }} >
          <Col xs={ 12 } >
            <h2>L'expert-comptable</h2>
            <p id="2" >
              Parce qu’il établit les comptes, votre Expert-comptable, interlocuteur privilégié de l’entreprise,<br/>
              Vous accompagne dans votre développement<br/>
              Vous aide à optimiser votre situation fiscale<br/>
              Vous aide en matière sociale, pour plus d’efficacité et de sécurité<br/>
              Comptez sur votre Expert-comptable pour vous simplifier l’entreprise.
            </p>
          </Col>
        </Row>

        <Row id="3" className="text-dark" style={{ paddingBottom: '5vh' }} >
          <Col xs={ 12 } >
            <h2>Comptabilité</h2>
            <p>
              L’expert-comptable apporte ses compétences en matière de comptabilité et de gestion par la mise en place d’une comptabilité adapté à l’entreprise et la définition d’une organisation administrative.<br/>
Ce qui permet de répondre aux obligations légales mais aussi d’assurer une meilleure gestion de votre entreprise par un suivi adapté.
            </p>
          </Col>
        </Row>

        <Row id="4" className="text-dark" style={{ paddingBottom: '5vh' }} >
          <Col xs={ 12 } >
            <h2>Paies et social</h2>
            <p>
              L’expert-comptable établit les contrats de travail et les fiches de paie en accord avec la législation du droit du travail et il prépare les déclarations sociales.
            </p>
          </Col>
        </Row>

        <Row id="5" className="text-dark" style={{ paddingBottom: '5vh' }} >
          <Col xs={ 12 } >
            <h2>Juridique</h2>
            <p>
              En matière juridique, l’expert-comptable assiste et conseille le chef d’entreprise dans la création de son entreprise sur les différents choix à opérer au niveau de la forme juridique (individuelle, SARL, SAS, SA…) et leurs incidences fiscales et sociales.<br/>
L’expert-comptable réalise le dossier de constitution, lors de la création de l’entreprise, puis assure le secrétariat juridique annuel et les différentes modifications en cours de vie sociale (que ce soit un changement de siège social, de dénomination, d’objet, de gérant, …l’augmentation du capital social, des cessions de parts sociales ou une dissolution et liquidation de l’entreprise).</p>
          </Col>
        </Row>

        <Row id="6" className="text-dark" style={{ paddingBottom: '5vh' }} >
          <Col xs={ 12 } >
            <h2>Fiscal</h2>
            <p>
              L’expert-comptable établit les déclarations fiscales des entreprises, des professions libérales et des particuliers.<br/>
L’expert-comptable aide le chef d’entreprise à trouver les meilleures solutions juridiques et fiscales dans le cadre des missions comptables qui lui sont confiées.
            </p>
          </Col>
        </Row>

        <Row id="7" className="text-dark" style={{ paddingBottom: '5vh' }} >
          <Col xs={ 12 } >
            <h2>Gestion</h2>
            <p>
              L’expert-comptable élabore les comptes annuels de votre entreprise et vous apporte une analyse financière approfondie pour vous permettre de mieux comprendre ce qui s’est passé durant l’exercice écoulé et de prendre les décisions appropriées
            </p>
          </Col>
        </Row>

        <Row id="8" className="text-dark" style={{ paddingBottom: '5vh' }} >
          <Col xs={ 12 } >
            <h2>Organisation</h2>
            <p>
              L’expert-comptable aide le chef d’entreprise à mettre en place les structures administratives, comptables et commerciales de l’entreprise.
            </p>
          </Col>
        </Row>

      </Container>
  </>
  );
}

export default Activites;
