import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Bar from '../components/Bar';


function Tools() {
  const smicHoraire = '11,65 € bruts';
  const smicMensuel = '1 766,92 € bruts';
  const plafondAnnuel = '46 368 €';
  const indiceDuCout = '1 886 €';
  const indiceDeReference = '119,70';

  return (
    <>
      <Container className="wg-100">
        <Row className="justify-content-center text-center" style={{ color: "#1155bf", paddingBottom: "10vh" }} >
          <h1>Outils</h1>
        </Row>
        <Row className="text-dark" >

          <Col xs={ 12 } >
            <h2>Chiffres clés :</h2>
            <p>
              Smic horaire au 1er janvier 2024 : <b>{ smicHoraire } de l'heure.</b>
              <br/>
              Smic mensuel pour 35 h au 1er janvier 2024 : <b>{ smicMensuel }</b>.
              <br/>
              Plafond annuel de Sécurité Sociale 2024 : <b>{ plafondAnnuel }</b>
              <br/>
              <a href="https://www.insee.fr/fr/themes/conjoncture/indice_icc.asp">
                Indice du coût de la construction au 3ème trimestre 2021 : <b>{ indiceDuCout }</b>
              </a>
              <br/>
              <a href="https://www.insee.fr/fr/statistiques/serie/001532540">
                Indice de référence des loyers commerciaux - 3ème trimestre 2021 : <b>{ indiceDeReference }</b>
              </a>
            </p>
          </Col>

        </Row>
        <Bar />

        <Row className="justify-content-center text-dark" style={{ paddingBottom: '5vh' }} >
          <Col xs={ 12 } >
            <h2>Simulateur d'embauche</h2>
    <iframe id="simulateurEmbauche" src="https://mon-entreprise.fr/iframes/simulateur-embauche?iframe&amp;integratorUrl=https%3A%2F%2Flefevre-ec.fr%2Ftools.php&amp;lang=fr" style={{ border: 'medium none', width: '100%', display: 'block', height: '610px', overflow: 'auto' }} allow="clipboard-write" title="simulateur embauche" allowFullScreen scrolling="auto" data-ss1622635529="1"></iframe>
          </Col>
        </Row>

        <Row className="justify-content-center text-dark" style={{ paddingBottom: '5vh' }} >
          <Col xs={ 12 } >
            <h2><a href="https://www.service-public.fr/simulateur/calcul/gratification-stagiaire">
                Simulateur de calcul de la gratification minimale
                d'un stagiaire (lien externe)
            </a></h2>
          </Col>
        </Row>

        <Row className="justify-content-center text-dark" style={{ paddingBottom: '5vh' }} >
          <Col xs={ 12 } >
            <h2><a href="https://www.alternance.emploi.gouv.fr/portail_alternance/jcms/hl_6238/simulateur-alternant">
                Simulateur du cout de l'embauche d'un apprenti (lien externe)
            </a></h2>
          </Col>
        </Row>

        <Row className="justify-content-center text-dark" >
          <Col xs={ 12 } >
            <h2><a href="https://autodiagnostics.experts-comptables.org/autodiag/autodiagnostic_cybersecurite/questionnaire.htm?id_EC=e8157921-00e1-3f41-7a75-53b55753ae22&email_EC=nl@lefevre-ec.fr#2">
                Autodiagnostic Cybersecurité (lien externe)
            </a></h2>
          </Col>
        </Row>

        <Bar />

        <Row className="justify-content-center text-dark" style={{ paddingBottom: '5vh' }} >
          <Col xs={ 12 } >
            <h2>
                Fichiers utiles
            </h2>
          </Col>
        </Row>

        <Row className="text-dark" style={{ paddingBottom: '5vh' }} >

          <Col xs={ 6 } md={ 4 } lg={ 3 } className="text-center" >
            <a href="download/note-de-frais.xlsx" >
              <img src="images/image-note-de-frais.png" alt="note de frais" />
              <br/>
              <h3>Note de Frais</h3>
              <p>Ce fichier permet de calculer les remboursements de frais.</p>
            </a>
          </Col>

          <Col xs={ 6 } md={ 4 } lg={ 3 } className="text-center" >
            <a href="https://www.economie.gouv.fr/particuliers/bareme-kilometrique" >
              <img src="images/fotolia-circulation-910.png" alt="bareme km" />
              <br/>
              <h3>Barèmes des indemnités kilomètriques</h3>
              <p>Tout savoir sur le barème des frais kilométriques</p>
            </a>
          </Col>

          <Col xs={ 6 } md={ 4 } lg={ 3 } className="text-center" >
            <a href="download/calcul-indemnite-km.xlsx" >
              <img src="images/image-indemnite-kilometrique.png" alt="indmnite km" />
              <br/>
              <h3>Indemnité kilomètrique</h3>
              <p>Ce fichier permet de calculer en fonction des
    déplacements le coût des indemnités kilométriques.</p>
            </a>
          </Col>

          <Col xs={ 6 } md={ 4 } lg={ 3 } className="text-center" >
            <a href="download/liste-cheque-emis.xls" >
              <img src="images/image-liste-cheques-emis.png" alt="cheque emis" />
              <br/>
              <h3>Liste des chèques émis</h3>
                <p>Ce fichier permet de lister les chèques
    émis triés par numéro de chèque.</p>
            </a>
          </Col>

          <Col xs={ 6 } md={ 4 } lg={ 3 } className="text-center" >
            <a href="download/LEC Modèle Caisse.xlsx" >
              <img src="images/image-liste-cheques-emis.png" alt="caisse annuel" />
              <br/>
              <h3>LEC Modèle Caisse annuel</h3>
            </a>
          </Col>

          <Col xs={ 6 } md={ 4 } lg={ 3 } className="text-center" >
            <a href="download/LEC Modèle Caisse mensuel.xlsx" >
              <img src="images/image-liste-cheques-emis.png" alt="caisse mensuel" />
              <br/>
              <h3>LEC Modèle Caisse mensuel</h3>
            </a>
          </Col>

          <Col xs={ 6 } md={ 4 } lg={ 3 } className="text-center" >
            <a href="download/Fiche prévention risques.xlsx" >
              <img src="images/compte_prevention_penibilite_340_158.jpg" alt="compte prevention" />
              <br/>
              <h3>
                Fiche de prévention des expositions à
                certains facteurs de risques professionnels
              </h3>
              <p>
                Cette fiche recense les expositions à facteurs
                de risques pour chaque salarié.
              </p>
            </a>
          </Col>

        </Row>

      </Container>
  </>
  );
}

export default Tools;
