import React, { useState, useRef, useEffect } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import { storage } from '../firebase';
import { Redirect } from 'react-router-dom';

function Fichiers() {
  const { currentUser } = useAuth();
  const filesRef = useRef<any>([]);
  const countRef = useRef(0);
  const [noFiles, setNoFiles] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (currentUser) {
      storage.ref('user').child(`${currentUser.uid}`).listAll().then(res => {
        if (!res.items.length) {
          setNoFiles(true);
        }
        res.items.forEach((itemRef) => {
          if (itemRef.name.endsWith('.zip')) {
            itemRef.getDownloadURL()
              .then((url) => {
                filesRef.current.push({
                  filename: itemRef.name,
                  url,
                  year: itemRef.name.slice(9, 13)
                })
                countRef.current += 1
                if (countRef.current === res.items.length) {
                  filesRef.current.sort((a: any, b: any) => {
                return a.year < b.year ? 1 : -1;
                  });
                  setFiles(filesRef.current);
                }
              })
          } else {
            countRef.current += 1
            if (countRef.current === res.items.length) {
              filesRef.current.sort((a: any, b: any) => {
                return a.year < b.year ? 1 : -1;
              });
              setFiles(filesRef.current);
            }
          }
        });

      });
    }
  }, [currentUser]);

  return (
    <>
      { (!currentUser || !currentUser.email) && <Redirect to="/" /> }
      <Container className="wg-100 actualites-parent">
        <Row className="justify-content-center text-center" style={{ color: "#1155bf", paddingBottom: "10vh" }} >
          <h1>Fichiers</h1>
        </Row>

    { files && files.map((value: any, index: any) => {
      return <Row className="actualites justify-content-center" style={{ paddingBottom: '5vh' }} >
          <Col xs={ 12 } >
            <Row>
              <Col>
                <a href={ value.url } >Télécharger mes archives { value.year }</a>
              </Col>
            </Row>
          </Col>
        </Row>
    })}
    { noFiles && <Row className="actualites justify-content-center" style={{ paddingBottom: '5vh' }} >
          <Col xs={ 12 } >
            <Row>
              <Col>
                <h2 >Vous n'avez pas de fichiers.</h2>
              </Col>
            </Row>
          </Col>
        </Row>
    }

  </Container>
</>
  );
}

export default Fichiers;
