import React from 'react';
import { Row, Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function Account() {
  const { currentUser } = useAuth();

  return (
    <>
      { (!currentUser || !currentUser.email) && <Redirect to="/" /> }
      <Container className="wg-100">
        <Row className="justify-content-center text-center" style={{ color: "#1155bf", paddingBottom: "10vh" }} >
          <h1 style={{ fontSize: '2.5rem' }} >Mon compte</h1>
        </Row>

      </Container>
  </>
  );
}

export default Account;
